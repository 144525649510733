import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password"];

  password() {
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text";
    } else {
      this.passwordTarget.type = "password";
    }
  }
}
