import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'rowTemplate', 'rows', 'removeButton' ]

  connect() {
    if (this.removeButtonTargets.length == 0) {
      this.appendRow();
    }
  }

  appendRow() {
    const row = document.importNode(this.rowTemplateTarget.content, true);
    this.rowsTarget.appendChild(row);

    this.updateRemoveButton();
  }

  add() {
    this.appendRow();
  }

  removeRow(event) {
    this.rowsTarget.removeChild(event.currentTarget.parentNode.parentNode);
    this.updateRemoveButton();
  }

  updateRemoveButton() {
    if(this.removeButtonTargets.length > 1) {
      this.removeButtonTargets.forEach((button) => button.classList.remove('hidden'))
    } else {
      this.removeButtonTargets.forEach((button) => button.classList.add('hidden'))
    }
  }
}
