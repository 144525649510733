import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["map"];

  connect() {
    if (this.hasMapTarget) {
      const lat = parseFloat(this.mapTarget.dataset.lat);
      const lon = parseFloat(this.mapTarget.dataset.lon);
      const location = { lat: lat, lng: lon };

      const map = new google.maps.Map(this.mapTarget, {
        zoom: 13,
        center: location,
        zoomControl: false,
        scaleControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      });
      const marker = new google.maps.Marker({ position: location, map });
    }
  }
}
