import ReadMore from "stimulus-read-more"

export default class extends ReadMore {
  static targets = [ "showLabel", "hideLabel" ]

  connect() {
    super.connect()
  }

  show (event) {
    this.open = true
    this.showLabelTarget.classList.add("hidden")
    this.hideLabelTarget.classList.remove("hidden")
    this.contentTarget.innerHTML = this.fullTarget.innerHTML
  }

  hide (event) {
    this.open = false
    this.showLabelTarget.classList.remove("hidden")
    this.hideLabelTarget.classList.add("hidden")
    this.contentTarget.innerHTML = this.originalContent
  }
}
