import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input", "image", "error"]

  changed() {
    Array.from(this.inputTarget.files).forEach(file => {
      const fileSize = Math.ceil(parseFloat((file.size / (1024*1024))))
      if (fileSize > 10) {
        this.inputTarget.value = ""
        this.errorTarget.textContent = "Profile photo exceeds file size limit. Please upload an image smaller than 10MB."
        this.errorTarget.classList.remove("hidden")
      } else if(!file.type.toLowerCase().startsWith("image/")) {
        this.inputTarget.value = ""
        this.errorTarget.textContent = "Invalid profile photo type. Please upload JPG, PNG, or GIF type image."
        this.errorTarget.classList.remove("hidden")
      } else {
        this.errorTarget.classList.add("hidden")
        const postURL = "/rails/active_storage/direct_uploads"
        const getURL = "/rails/active_storage/blobs"
        const upload = new DirectUpload(file, postURL, this)

        this._disableFormSubmit()

        upload.create((error, blob) => {
          this.hiddenInput().value = blob.signed_id
          this.imageTarget.src = `${getURL}/${blob.signed_id}/${blob.filename}`

          this._enableFormSubmit()
        })
      }
    })
  }

  hiddenInput() {
    if (this._hiddenInput == undefined ) {
      this._hiddenInput = document.createElement('input')
      this._hiddenInput.name = this.inputTarget.name
      this._hiddenInput.type = "hidden"
      this.inputTarget.parentNode.insertBefore(this._hiddenInput, this.inputTarget.nextSibling)
    }
    return this._hiddenInput
  }

  _disableFormSubmit() {
    const submitButton = document.querySelector("button[type='submit']")

    submitButton.firstElementChild.classList.remove('hidden', 'cursor-pointer')
    submitButton.classList.add('cursor-wait')
    submitButton.classList.remove('cursor-pointer')
    submitButton.disabled = true
  }

  _enableFormSubmit() {
    const submitButton = document.querySelector("button[type='submit']")

    submitButton.firstElementChild.classList.add('hidden')
    submitButton.classList.add('cursor-pointer')
    submitButton.classList.remove('cursor-wait')
    submitButton.disabled = false
  }
}
