import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "addItem",
    "template",
    "removeButton",
  ]

  connect() {
    this.updateRemoveButton()
  }

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addItemTarget.insertAdjacentHTML("beforebegin", content)
    this.updateRemoveButton()
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = "true"
    item.style.display = "none"
    this.updateRemoveButton()
  }

  updateRemoveButton() {
    if(this.removeButtonTargets.length > 1) {
      this.removeButtonTargets.forEach((button) => button.classList.remove('hidden'))
    } else {
      this.removeButtonTargets.forEach((button) => button.classList.add('hidden'))
    }
  }
}
