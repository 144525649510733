import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["master", "checkbox"];

  connect() {
    this.updateMasterCheckboxState();
  }

  toggleAll() {
    const newState = !this.masterTarget.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = newState;
    });
    this.updateMasterCheckboxState();
  }

  updateMasterCheckboxState() {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked);
    this.masterTarget.checked = !allChecked;
  }
}
