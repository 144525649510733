import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submit"];
  static values = { enabled: String }

  connect() {
    this.checkCheckboxes();
  }

  checkCheckboxes() {
    const isChecked = this.checkboxTargets.some((checkbox) => checkbox.checked);
    this.submitTarget.disabled = !isChecked;
    if (!isChecked) {
      this.submitTarget.classList.add("opacity-50")
    } else {
      this.submitTarget.classList.remove("opacity-50")
    }
    this.submitTarget.disabled = !isChecked;
  }
}
