import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["toggleable", "showLabel", "hideLabel"]
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get("class") || "hidden"
  }

  toggle(event) {
    if (event.target.getAttribute('type') != "checkbox") {
      event.preventDefault()
    }

    this.openValue = !this.openValue
  }

  hide(event) {
    event.preventDefault()

    this.openValue = false
    this.showLabelTarget.classList.remove("hidden")
    this.hideLabelTarget.classList.add("hidden")
  }

  show(event) {
    event.preventDefault();

    this.openValue = true
    this.showLabelTarget.classList.add("hidden")
    this.hideLabelTarget.classList.remove("hidden")
  }

  openValueChanged() {
    if (!this.toggleClass) { return }

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)
    })
  }
}
