import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "background",
    "slider",
    "checkbox",
  ]

  connect() {
    this.refresh();
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.refresh();

    const event = new Event("switch-toggle", { bubbles: true, cancelable: true });
    window.dispatchEvent(event);
  }

  refresh() {
    if (this.checkboxTarget.checked) {
      this.backgroundTarget.classList.add("bg-indigo-600")
      this.backgroundTarget.classList.remove("bg-gray-200")
      this.sliderTarget.classList.add("translate-x-5")
      this.sliderTarget.classList.remove("translate-x-0")
    } else {
      this.backgroundTarget.classList.add("bg-gray-200")
      this.backgroundTarget.classList.remove("bg-indigo-600")
      this.sliderTarget.classList.add("translate-x-0")
      this.sliderTarget.classList.remove("translate-x-5")
    }
  }

}
