import { Controller } from "stimulus"
import {enter, leave} from 'el-transition';

export default class extends Controller {
  static targets = [ 'menuContainer', 'menuOverlay', 'menu', 'menuCloseButton' ]

  connect() {
  }

  open(event) {
    enter(this.menuContainerTarget);
    enter(this.menuOverlayTarget);
    enter(this.menuTarget);
    enter(this.menuCloseButtonTarget);
  }

  close(event) {
    leave(this.menuContainerTarget);
    leave(this.menuOverlayTarget);
    leave(this.menuTarget);
    leave(this.menuCloseButtonTarget);
  }

}
