import { Controller } from "@hotwired/stimulus";
import { template } from "lodash"

export default class extends Controller {
  static targets = ["map", "contentTemplate"];
  static values = {
    places: Array,
    markerUrl: String,
    center: Object,
  };

  connect() {
    const center = this.centerValue;

    const map = new google.maps.Map(this.mapTarget, {
      zoom: 9,
      center: center,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    });

    var body = document.body, html = document.documentElement;
    var height = this.mapTarget.parentNode.parentNode.offsetHeight
    this.mapTarget.style.height = height + "px";

    const contentString = this.contentTemplateTarget.innerHTML;
    this.contentTemplate = template(this.contentTemplateTarget.innerHTML);

    this.placesValue.map(place => {
      const location = place.location;
      const lat = parseFloat(location.lat);
      const lon = parseFloat(location.lon);
      const position = { lat: lat, lng: lon };

      this.contentData = {
        name: place.name,
        address: [place.address.city, place.address.state].join(", "),
        url: place.url,
      }

      const marker = new google.maps.Marker({
        position: position,
        icon: this.markerUrlValue,
        map
      });

      // const infowindow = new google.maps.InfoWindow({
      //   content: this.contentTemplate(this.contentData)
      // });
      // marker.addListener("click", () => {
      //   infowindow.open({
      //     anchor: marker,
      //     map,
      //     shouldFocus: false,
      //   });
      // });
    })
  }
}
