import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = [ "filter", "option", "options", "caret" ]

  connect() {
    useClickOutside(this);
    this.optionTargets.forEach(element => {
      if (element.dataset.selected === "true") {
        element.children[1].classList.remove("invisible");
        element.children[0].classList.remove("font-normal");
        element.children[0].classList.add("font-semibold");
      }
    })
  }

  focusin() {
    this.filterTarget.value = "";
    this.optionTargets.forEach(option => option.firstElementChild.innerText = option.dataset.value);
    this.optionsTarget.classList.remove("hidden");
    if (this.hasCaretTarget) {
      this.caretTarget.style.cssText = "transform: scaleY(-1)";
    }
    this.optionsTarget.querySelectorAll("li[data-selected='true']").forEach(element => element.scrollIntoView({ block: "nearest" }))
  }

  selected(event) {
    this.optionTargets.forEach(element => {
      if (element === event.currentTarget) {
        element.children[1].classList.remove("invisible");
        element.children[0].classList.remove("font-normal");
        element.children[0].classList.add("font-semibold");
        element.dataset.selected = true;
      } else {
        element.children[1].classList.add("invisible");
        element.children[0].classList.add("font-normal");
        element.children[0].classList.remove("font-semibold");
        element.dataset.selected = false;
      }
    })

    this.filterTarget.value = event.currentTarget.children[0].innerText
    this.optionTargets.forEach(option => option.classList.remove("hidden"));
    this.optionsTarget.classList.toggle("hidden");
    if (this.hasCaretTarget) {
      this.caretTarget.style.cssText = "";
    }
  }

  filterOptions() {
    const filterValue = this.filterTarget.value.toLowerCase();
    let optionValue = null;
    let highlightedOption = null;

    this.optionTargets.forEach(option => {
      optionValue = option.dataset.value
      highlightedOption = optionValue.replace(new RegExp(filterValue, 'i'), '<span class="font-bold">$&</span>')
      if(!optionValue.toLowerCase().includes(filterValue.toLowerCase())) {
        option.classList.add("hidden");
      } else {
        option.firstElementChild.innerHTML = highlightedOption;
        option.classList.remove("hidden");
      }
    })
  }

  clickOutside(event) {
    const selectedOption = this.optionsTarget.querySelector("li[data-selected='true']");
    if (selectedOption) {
      this.filterTarget.value = selectedOption.dataset.value;
    } else {
      this.filterTarget.value = "";
    }
    this.optionTargets.forEach(option => option.classList.remove("hidden"));
    this.optionsTarget.classList.add("hidden");
    if (this.hasCaretTarget) {
      this.caretTarget.style.cssText = "";
    }
  }
}
