import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "background",
    "slider",
    "cost",
    "checkbox",
    "profit",
  ]

  connect() {
    this.refresh();
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.refresh();
  }

  refresh() {
    let node = this.checkboxTarget.parentNode.previousElementSibling
    if (this.checkboxTarget.checked) {
      this._disableCost()
      this.backgroundTarget.classList.add("bg-indigo-600")
      this.backgroundTarget.classList.remove("bg-gray-200")
      this.sliderTarget.classList.add("translate-x-5")
      this.sliderTarget.classList.remove("translate-x-0")
    } else {
      this._enableCost()
      this.backgroundTarget.classList.add("bg-gray-200")
      this.backgroundTarget.classList.remove("bg-indigo-600")
      this.sliderTarget.classList.add("translate-x-0")
      this.sliderTarget.classList.remove("translate-x-5")
    }
  }

  _disableCost() {
    this.costTarget.value = "0"
    this.profitTarget.textContent = "0.00 USD"
    this.costTarget.disabled = true
    this.costTarget.classList.add("opacity-50")
  }

  _enableCost() {
    this.costTarget.disabled = false
    this.costTarget.classList.remove("opacity-50")
  }
}
