import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "button",
    "inputArea",
  ]

  changeText() {
    const inputText = this.inputAreaTarget.value
    if (inputText.trim() !== "") {
      this.buttonTarget.textContent = "Next"
    } else {
      this.buttonTarget.textContent = "Skip"
    }
  }
}
