import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["container"]

  close(e) {
    document.querySelector("#modal-background").remove()

    this.element.parentElement.removeAttribute("src")
    this.containerTarget.remove()
  }

  closeBackground(e) {
    // if (e.target === this.containerTarget) {
    //   this.close(e);
    // }
  }
}
