import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["button"]

  connect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.click()
    }
  }
}
