import PlacesAutocomplete from "stimulus-places-autocomplete"

export default class extends PlacesAutocomplete {
  connect() {
    super.connect()
  }

  initAutocomplete () {
    super.initAutocomplete()
  }

  placeChanged () {
    super.placeChanged()

    const event = new Event("place-changed", { bubbles: true, cancelable: true });
    window.dispatchEvent(event);
  }

  get autocompleteOptions () {
    return {
      fields: ["address_components", "geometry"],
      types: ["postal_code"],
      componentRestrictions: {
        country: ["us", "ca", "gb", "au"]
      },
    }
  }
}
