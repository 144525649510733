import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "label", "labelIcon" ]

  connect() {}

  toggle(event) {
    if (this.contentTarget.clientHeight) {
      this.contentTarget.style.height = 0;
      this.contentTarget.style.marginTop = "0px";
    } else {
      this.contentTarget.style.height = this.contentTarget.scrollHeight + "px";
      this.contentTarget.style.marginTop = "12px";
    }
    this.labelIconTarget.classList.toggle("rotate-180");
  }

}
