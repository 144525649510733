import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "simpleContent"]

  connect() {
    window.addEventListener("scroll", this.handleUpdateContent.bind(this));
    // scroll to the top to fix an issue on mobile devices
    window.scroll(0, 0)
    this.simpleContentTarget.classList.add("hidden");
    this.contentTarget.classList.remove("hidden");
  }

  handleUpdateContent() {
    if(window.scrollY === 0) {
      this.simpleContentTarget.classList.add("hidden");
      this.contentTarget.classList.remove("hidden");
    } else {
      this.simpleContentTarget.classList.remove("hidden");
      this.contentTarget.classList.add("hidden");
    }
  }

}
