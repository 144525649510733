import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'background', 'slider', 'checkbox' ]

  connect() {
    this.refresh();
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.refresh();
  }

  refresh() {
    let node = this.checkboxTarget.parentNode.previousElementSibling
    if (this.checkboxTarget.checked) {
      while(node != null) {
        node.classList.add('hidden');
        node = node.previousElementSibling
      }
      this.backgroundTarget.classList.add("bg-bornbir-light-purple")
      this.backgroundTarget.classList.remove("bg-gray-200")
      this.sliderTarget.classList.add("translate-x-5")
      this.sliderTarget.classList.remove("translate-x-0")
    } else {
      while(node != null) {
        node.classList.remove('hidden');
        node = node.previousElementSibling
      }
      this.backgroundTarget.classList.add("bg-gray-200")
      this.backgroundTarget.classList.remove("bg-bornbir-light-purple")
      this.sliderTarget.classList.add("translate-x-0")
      this.sliderTarget.classList.remove("translate-x-5")
    }
  }
}
