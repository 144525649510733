import { Controller } from '@hotwired/stimulus'
import { SwiperOptions } from 'swiper'
import Swiper from 'swiper/swiper-bundle'

export default class extends Controller {
  static values = { navigation: String }

  connect () {
    this.swiper = new Swiper(this.element, {
      spaceBetween: 20,
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: `.${this.navigationValue}-next`,
        prevEl: `.${this.navigationValue}-prev`,
      },
    });

  }

  disconnect () {
    this.swiper.destroy()
    this.swiper = undefined
  }
}
