import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "cost",
    "currency",
    "profit",
  ]

  connect() {
    this.calculate()
  }

  calculate() {
    let cost = Number(this.costTarget.value)
    let currency = this.currencyTarget.value
    let profit = (cost * 0.9).toLocaleString(0.00, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    this.profitTarget.textContent = this._currencySymbol(currency) + profit + " " + currency
  }

  preventNonNumericInput(event) {
    if (event.data && !/\d|\./.test(event.data)) {
      event.preventDefault();
    }
  }

  _currencySymbol(currency) {
    if(currency === "GBP") {
      return "£";
    } else {
      return "$";
    }
  }
}
