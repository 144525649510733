import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() { }

  showModal(event) {
    event.target.querySelector("svg").classList.remove("hidden");
  }
}
