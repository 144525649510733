import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'question',
    'questionErrorMsg',
    'answer',
    'answerErrorMsg',
    'questions',
    'questionTemplate',
    'slideover',
  ]

  connect() {}

  add() {
    this._resetErrorState();
    this.questionTarget.value = ""
    this.answerTarget.value = ""
    delete this.slideoverTarget.dataset.questionIndex;
    this.slideoverTarget.classList.remove('hidden');
  }

  edit(event) {
    this._resetErrorState();
    const questionNode = event.currentTarget.parentNode.parentNode.parentNode
    const questionIndex = Array.from(this.questionsTarget.querySelectorAll('ul > li')).indexOf(questionNode);
    const question = questionNode.querySelector('h3').innerText
    const answer = questionNode.querySelector('p').innerText
    this.questionTarget.value = question
    this.answerTarget.value = answer
    this.slideoverTarget.classList.remove('hidden');
    this.slideoverTarget.dataset.questionIndex = questionIndex
  }

  delete(event) {
    const questionNode = event.currentTarget.parentNode.parentNode.parentNode
    const hiddenInputNode = questionNode.nextElementSibling;
    questionNode.parentNode.removeChild(questionNode);
    hiddenInputNode.parentNode.removeChild(hiddenInputNode);
  }

  cancel() {
    this.slideoverTarget.classList.add('hidden');
  }

  save() {
    if (this._validate()) {
      this.slideoverTarget.classList.add('hidden');
      const question = this.questionTarget.value;
      const answer = this.answerTarget.value;
      const questionIndex = this.slideoverTarget.dataset.questionIndex
      if(questionIndex) {
        this.updateQuestion(questionIndex, question, answer)
      } else {
        this.appendQuestion(question, answer);
      }
    }
  }

  appendQuestion(question, answer) {
    const template = document.importNode(this.questionTemplateTarget.content, true);
    const div = document.createElement('div');
    div.append(template);
    div.innerHTML = div.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    div.querySelector('h3').innerText = question
    div.querySelector('input').value = question
    div.querySelector('p').innerText  = answer
    div.querySelector('textarea').value = answer
    this.questionsTarget.appendChild(div.firstElementChild);
  }

  updateQuestion(questionIndex, question, answer) {
    const questionNode = this.questionsTarget.querySelectorAll('ul > li').item(questionIndex)
    questionNode.querySelector('h3').innerText = question
    questionNode.querySelector('input').value = question
    questionNode.querySelector('p').innerText = answer
    questionNode.querySelector('textarea').value = answer
  }

  _validate() {
    const answerResult = this._validateInputField(this.answerTarget, this.answerErrorMsgTarget)
    const questionResult = this._validateInputField(this.questionTarget, this.questionErrorMsgTarget)

    return answerResult && questionResult;
  }

  _validateInputField(fieldTarget, fieldErrorMsgTarget) {
    if(this._isEmpty(fieldTarget.value)) {
      fieldTarget.parentNode.classList.add('field_with_errors');
      fieldErrorMsgTarget.classList.remove('hidden');
      return false;
    } else {
      fieldTarget.parentNode.classList.remove('field_with_errors');
      fieldErrorMsgTarget.classList.add('hidden');
      return true;
    }
  }

  _isEmpty(str) {
    return !str.trim().length;
  }

  _resetErrorState() {
    this.answerTarget.parentNode.classList.remove('field_with_errors');
    this.answerErrorMsgTarget.classList.add('hidden');

    this.questionTarget.parentNode.classList.remove('field_with_errors');
    this.questionErrorMsgTarget.classList.add('hidden');
  }
}
