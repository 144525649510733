import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "showLabel", "hideLabel" ]
  static values = { line: String }

  connect() {
    if (this.contentTarget.offsetHeight < this.contentTarget.scrollHeight || this.contentTarget.offsetWidth < this.contentTarget.scrollWidth) {
      this.showLabelTarget.classList.remove("hidden")
    }
  }

  toggle(event) {
    if (this.contentTarget.classList.contains(`line-clamp-${this.lineValue}`)) {
      this.showLabelTarget.classList.add("hidden")
      this.hideLabelTarget.classList.remove("hidden")
    } else {
      this.showLabelTarget.classList.remove("hidden")
      this.hideLabelTarget.classList.add("hidden")
    }
    this.contentTarget.classList.toggle(`line-clamp-${this.lineValue}`)
  }
}
