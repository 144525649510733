import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'name',
    'nameErrorMsg',
    'description',
    'descriptionErrorMsg',
    'packages',
    'packageTemplate',
    'slideover',
    'price',
    'priceErrorMsg',
    'priceType',
    'priceTypeErrorMsg',
    'priceCurrency',
  ]

  connect() {}

  add() {
    this._resetErrorState();
    this.nameTarget.value = ""
    this.descriptionTarget.value = ""
    this.priceTarget.value = ""
    this.priceTypeTargets.forEach(e => e.checked = false)
    delete this.slideoverTarget.dataset.packageIndex;
    this.slideoverTarget.classList.remove('hidden');
  }

  edit(event) {
    this._resetErrorState();
    const packageNode = event.currentTarget.parentNode.parentNode.parentNode
    const packageIndex = Array.from(this.packagesTarget.querySelectorAll('ul > li')).indexOf(packageNode);
    const name = packageNode.querySelector('h3').innerText
    const description = packageNode.querySelector('li > p').innerText
    const price = packageNode.querySelectorAll('li > div > div > p')[0].innerText.replaceAll(',', '')
    const priceCurrency = packageNode.querySelectorAll('li > div > div > p')[1].innerText
    const priceType = packageNode.querySelectorAll('li > div > div > p')[2].innerText
    this.nameTarget.value = name
    this.descriptionTarget.value = description
    this.priceTarget.value = price
    this.priceTypeTargets.find(e => e.value == priceType).checked = true;
    this.priceCurrencyTarget.value = priceCurrency
    this.slideoverTarget.classList.remove('hidden');
    this.slideoverTarget.dataset.packageIndex = packageIndex
  }

  delete(event) {
    const packageNode = event.currentTarget.parentNode.parentNode.parentNode;
    const hiddenInputNode = packageNode.nextElementSibling;
    packageNode.parentNode.removeChild(packageNode);
    hiddenInputNode.parentNode.removeChild(hiddenInputNode);
  }

  cancel() {
    this.slideoverTarget.classList.add('hidden');
  }

  save() {
    if(this._validate()) {
      this.slideoverTarget.classList.add('hidden');
      const name = this.nameTarget.value;
      const description = this.descriptionTarget.value;
      const price = this.priceTarget.value;
      const priceType = this.priceTypeTargets.find(e => e.checked).value;
      const currency = this.priceCurrencyTarget.value;
      const packageIndex = this.slideoverTarget.dataset.packageIndex
      if(packageIndex) {
        this.updatePackage(packageIndex, name, description, price, priceType, currency)
      } else {
        this.appendPackage(name, description, price, priceType, currency);
      }
    }
  }

  preventNonNumericInput(event) {
    if (event.data && !/\d|\./.test(event.data)) {
      event.preventDefault();
    }
  }

  appendPackage(name, description, price, priceType, priceCurrency) {
    const template = document.importNode(this.packageTemplateTarget.content, true);
    const div = document.createElement('div');
    div.append(template);
    div.innerHTML = div.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    div.querySelector('h3').innerText = name
    div.querySelector('li > input[type="text"').value = name

    div.querySelector('li > p').innerText  = description
    div.querySelector('li > textarea').value  = description

    div.querySelectorAll('li > div > div > p')[1].innerText  = priceCurrency;
    div.querySelectorAll('li > div > div > p')[2].innerText  = priceType;
    div.querySelector('li > div > div > span').textContent  = this._currencySymbol(priceCurrency);
    div.querySelectorAll('li > div > div > input[type="text"]')[0].value  = priceCurrency;
    div.querySelectorAll('li > div > div > input[type="text"]')[1].value  = priceType;

    div.querySelectorAll('li > div > div > p')[0].innerText  = Number(price).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    div.querySelector('li > div > div > input[type="number"]').value  = Number(price);

    this.packagesTarget.appendChild(div.firstElementChild);
  }

  updatePackage(packageIndex, name, description, price, priceType, priceCurrency) {
    const packageNode = this.packagesTarget.querySelectorAll('ul > li').item(packageIndex)
    packageNode.querySelector('h3').innerText = name
    packageNode.querySelector('li > input[type="text"').value = name

    packageNode.querySelector('li > p').innerText = description
    packageNode.querySelector('li > textarea').value  = description

    packageNode.querySelectorAll('li > div > div > p')[0].innerText  = Number(price).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    packageNode.querySelector('li > div > div > input[type="number"]').value  = Number(price);

    packageNode.querySelectorAll('li > div > div > p')[1].innerText  = priceCurrency;
    packageNode.querySelectorAll('li > div > div > p')[2].innerText  = priceType;
    packageNode.querySelector('li > div > div > span').textContent  = this._currencySymbol(priceCurrency);
    packageNode.querySelector('li > div > div > input[type="text"]').value  = priceType;
    packageNode.querySelectorAll('li > div > div > input[type="text"]')[0].value  = priceCurrency;
    packageNode.querySelectorAll('li > div > div > input[type="text"]')[1].value  = priceType;
  }

  _currencySymbol(currency) {
    if(currency === "GBP") {
      return "£";
    } else {
      return "$";
    }
  }

  _validate() {
    const nameResult = this._validateInputField(this.nameTarget, this.nameErrorMsgTarget)
    const descriptionResult = this._validateInputField(this.descriptionTarget, this.descriptionErrorMsgTarget)
    const priceResult = this._validateInputField(this.priceTarget, this.priceErrorMsgTarget)
    const priceTypeResult = this._validatePriceTypeField();

    return nameResult && descriptionResult && priceResult && priceTypeResult;
  }

  _validatePriceTypeField() {
    if (!this.priceTypeTargets.find(e => e.checked)) {
      this.priceTypeTargets.forEach(e => e.parentNode.classList.add('field_with_errors'));
      this.priceTypeErrorMsgTarget.classList.remove('hidden');
      return false;
    } else {
      this.priceTypeTargets.forEach(e => e.parentNode.classList.remove('field_with_errors'));
      this.priceTypeErrorMsgTarget.classList.add('hidden');
      return true;
    }
  }

  _validateInputField(fieldTarget, fieldErrorMsgTarget) {
    if(this._isEmpty(fieldTarget.value)) {
      fieldTarget.parentNode.classList.add('field_with_errors');
      fieldErrorMsgTarget.classList.remove('hidden');
      return false;
    } else {
      fieldTarget.parentNode.classList.remove('field_with_errors');
      fieldErrorMsgTarget.classList.add('hidden');
      return true;
    }
  }

  _isEmpty(str) {
    return !str.trim().length;
  }

  _resetErrorState() {
    this.nameTarget.parentNode.classList.remove('field_with_errors');
    this.nameErrorMsgTarget.classList.add('hidden');

    this.descriptionTarget.parentNode.classList.remove('field_with_errors');
    this.descriptionErrorMsgTarget.classList.add('hidden');

    this.priceTarget.parentNode.classList.remove('field_with_errors');
    this.priceErrorMsgTarget.classList.add('hidden');

    this.priceTypeTargets.forEach(e => e.parentNode.classList.remove('field_with_errors'));
    this.priceTypeErrorMsgTarget.classList.add('hidden');
  }
}
