import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = [ "option", "options", "filter", "tags", "tagTemplate", "caret" ]

  connect() {
    useClickOutside(this)
    this.refreshTags();
    this.refreshCount();
  }

  optionChecked(event) {
    this.refreshTags()
  }

  removeTag(event) {
    this.optionsTarget.querySelector("li input[data-label='" + event.currentTarget.parentNode.dataset.value + "']:checked").checked = false
    event.currentTarget.parentNode.parentNode.removeChild(event.currentTarget.parentNode);
    this.refreshCount();
  }

  refreshTags() {
    this.resetTags();
    Array.from(this.optionsTarget.querySelectorAll("li"))
      .filter(e => e.querySelector("input:checked"))
      .forEach(e => this.appendTag(e.textContent.trim(), e.dataset.value))
  }

  refreshCount() {
    const tagsCount = this.tagsTarget.querySelectorAll("li").length
    this.filterTarget.value = tagsCount > 0 ? tagsCount + " selected" : "";
  }

  resetTags() {
    this.tagsTarget.querySelectorAll("li")
      .forEach(element => { this.tagsTarget.removeChild(element) })
  }

  appendTag(label, value) {
    const tag = document.importNode(this.tagTemplateTarget.content, true);
    tag.children[0].dataset.value = value;
    tag.querySelectorAll("label")[0].innerText = label;
    this.tagsTarget.appendChild(tag);
  }

  filterOptions() {
    const filterValue = this.filterTarget.value.toLowerCase();
    let optionValue = null;
    let highlightedOption = null;

    this.optionTargets.forEach(option => {
      optionValue = option.dataset.value;
      highlightedOption = optionValue.replace(new RegExp(filterValue, 'i'), '<span class="font-bold">$&</span>')
      if(!optionValue.toLowerCase().includes(filterValue.toLowerCase())) {
        option.classList.add("hidden");
      } else {
        option.querySelector('label').innerHTML = highlightedOption;
        option.classList.remove("hidden");
      }
    })
  }

  focusin() {
    this.optionTargets.forEach(option => option.querySelector('label').innerText = option.dataset.value);
    this.optionsTarget.classList.remove("hidden");
    this.caretTarget.style.cssText = "transform: scaleY(-1)";
    this.filterTarget.value = "";
  }

  clickOutside(event) {
    this.optionsTarget.classList.add("hidden");
    if (this.hasCaretTarget) {
      this.caretTarget.style.cssText = "";
    }
    this.refreshCount();
    this.optionTargets.forEach(option => option.classList.remove("hidden"));
  }
}
