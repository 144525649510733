import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("scroll", this.scrollHandler);
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    // Define the threshold when the sticky navigation should appear
    const threshold = 600;

    if (scrollY > threshold) {
      // Show the sticky navigation
      this.element.classList.add("fixed");
    } else {
      // Hide the sticky navigation
      this.element.classList.remove("fixed");
    }
  };
}
