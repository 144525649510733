import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "currentPage", "item" ]

  prev() {
    if (this.currentPage() > 1) {
      this.showPage(this.currentPage() - 1);
    } else {
      this.showPage(this.currentPageTarget.textContent - 1);
    }
  }

  next() {
    if (this.currentPage() < this.totalPages()) {
      this.showPage(this.currentPage() + 1);
    } else {
      this.showPage(this.currentPageTarget.textContent + 1);
    }
  }

  currentPage() {
    return parseInt(this.currentPageTarget.textContent) || 1;
  }

  perPage() {
    return parseInt(this.data.get("per-page")) || 3;
  }

  totalPages() {
    return Math.ceil(this.itemTargets.length / this.perPage());
  }

  showPage(page) {
    if (page > 0 && page <= this.totalPages()) {
      this.currentPageTarget.textContent = page;

      this.itemTargets.forEach((item, index) => {
        if (index < (page - 1) * this.perPage() || index >= page * this.perPage()) {
          item.classList.add("hidden");
        } else {
          item.classList.remove("hidden");
        }
      })
    }
  }
}
