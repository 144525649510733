import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "card",
    "checkbox",
  ]

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.cardTarget.classList.remove("border-bornbir-gray-300")
      this.cardTarget.classList.add("border-bornbir-light-purple")
    } else {
      this.cardTarget.classList.remove("border-bornbir-light-purple")
      this.cardTarget.classList.add("border-bornbir-gray-300")
    }
  }
}
