import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "cost",
    "free",
    "freeToggle",
    "paymentOptionYes",
    "paymentOptionNo",
    "freeBanner",
    "paidBanner",
  ]

  connect() {
    if (this.paymentOptionYesTarget.checked == true) {
      this.show()
    } else if (this.paymentOptionNoTarget.checked == true) {
      this.hide()
    } else {
      this._hideCost()
    }
  }

  show() {
    this._showCost()
    this._showPaidBanner()
    this._hideFreeBanner()
  }

  hide() {
    this._hideCost()
    this._showFreeBanner()
    this._hidePaidBanner()
  }

  _hideCost() {
    this.costTarget.classList.add("hidden")
    this.freeTarget.classList.remove("hidden")
  }

  _showCost() {
    this.costTarget.classList.remove("hidden")
    this.freeTarget.classList.add("hidden")
  }

  _hidePaidBanner() {
    this.paidBannerTarget.classList.add("hidden")
  }

  _showPaidBanner() {
    this.paidBannerTarget.classList.remove("hidden")
  }

  _hideFreeBanner() {
    this.freeBannerTarget.classList.add("hidden")
  }

  _showFreeBanner() {
    this.freeBannerTarget.classList.remove("hidden")
  }
}
