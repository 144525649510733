window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
document.addEventListener("turbo:load", function(event) {
  gtag(
    'config',
    '<%= ENV["GA_TRACKING_ID"] || "G-5T42X2B72K" %>',
    {
      page_location: event.detail.url,
      page_path: location.pathname,
      page_title: document.title,
    });
  gtag('config', 'AW-580857994');
})

export default gtag;
