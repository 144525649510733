import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "option",
    "speciality",
  ]

  connect() {
    this.optionTargets.forEach(element => {
      if (element.dataset.selected === "true" && element.children[0].innerText.trim() == "Doula") {
        this.show()
      }
    })
  }

  selected(event) {
    if (event.currentTarget.children[0].innerText == "Doula") {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.specialityTarget.classList.remove("hidden");
  }

  hide() {
    this.specialityTarget.classList.add("hidden");
  }
}
