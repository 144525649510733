import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"

export default class extends Controller {
  static values = { message: String }

  connect() {
    const options = {
      arrow: true,
      placement: "top",
      animation: "fade",
      theme: "translucent",
      trigger: "click",
      content: this.messageValue,
      allowHTML: true,
    }
    this.tooltip = tippy(this.element, options);
  }
}
