import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "scrollable", "reviews" ]
  static values = { review: String }

  initialize() {
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    this.scrollableTargets.forEach(target => {
      target.addEventListener('click', this.scroll)
    })
    if (this.reviewValue !== "") {
      this.reviewsTarget.click()
    }
  }

  disconnect() {
    this.scrollableTargets.forEach(target => {
      target.removeEventListener('click', this.scroll)
    })
  }

  scroll(event) {
    event.preventDefault()

    this.scrollableTargets.forEach(target => {
      target.parentElement.classList.remove('border-b-2')
    })
    event.currentTarget.parentElement.classList.add('border-b-2')

    const id = event.currentTarget.hash.replace(/^#/, '')
    const target = document.getElementById(id)

    if (!target) {
      console.warn(`[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`)
      return
    }

    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition - this.offset

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior
    })
  }

  get offset() {
    if (this.hasOffsetValue) {
      return this.offsetValue
    }

    if (this.defaultOptions.offset !== undefined) {
      return this.defaultOptions.offset
    }

    return 10
  }

  get behavior() {
    return this.behaviorValue || this.defaultOptions.behavior || 'smooth'
  }

  get defaultOptions() {
    return {
      offset: 378,
    }
  }
}
